import { useEffect, useState } from 'react';

const COOKIE_NAME = 'AB_TEST';
export const useGetLpPattern = () => {
    const [isPatternB, setIsPatternB] = useState(false);

    useEffect(() => {
        const cookies = document.cookie.split(';');
        const targetLp = cookies.find(
            (cookie) => cookie.split('=')[0].trim() === COOKIE_NAME.trim()
        );
        if (targetLp) {
            setIsPatternB(targetLp.split('=')[1] === 'B');
        }
    }, []);

    return isPatternB;
};
