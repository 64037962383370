import classNames from 'classnames';

type Props = {
    clicked: boolean
    onClick: () => void;
};

const HamburgerMenu: React.FC<Props> = ({ clicked, onClick }) => {
    return (
        <span
            className="relative w-8 h-5 my-auto"
            onClick={onClick}
        >
            {[...Array(3)].map((_, i) => (
                <i
                    key={i}
                    className={classNames(
                        'transition-all w-full h-0.5 bg-primary absolute duration-500',
                        { 'top-0': i === 0 },
                        {
                            'translate-y-3 rotate-[-45deg]':
                                i === 0 && clicked
                        },
                        { 'top-0 bottom-0 m-auto': i === 1 },
                        { 'opacity-0': i === 1 && clicked },
                        { 'bottom-0': i === 2 },
                        {
                            'translate-y-[-6px] rotate-45':
                                i === 2 && clicked
                        }
                    )}
                ></i>
            ))}
        </span>

    );
};

export default HamburgerMenu;